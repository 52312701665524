import React from "react"

export default props => (
  <div class="email-container">
    <form
      action="https://buttondown.email/api/emails/embed-subscribe/trackandtower"
      method="post"
      target="popupwindow"
      onsubmit="window.open('https://buttondown.email/trackandtower', 'popupwindow')"
      class="embeddable-buttondown-form email-signup"
    >
      <h6 for="bd-email" class="email-lead">
        Subscribe for updates on my latest posts!
      </h6>
      <div class="email-wrapper">
        <input
          type="email"
          name="email"
          id="bd-email"
          placeholder="Email address"
        />
        <input type="hidden" value="1" name="embed" />
        <button class="" type="submit">
          Subscribe
        </button>
      </div>
    </form>
  </div>
)
