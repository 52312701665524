import React from "react"
import { Link } from "gatsby"

export default props => (
  <article>
    <Link to={props.node.fields.slug} className="post-card-link">
      <div
        className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
          props.postClass
        } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
        style={
          props.node.frontmatter.thumbnail && {
            backgroundImage: `url(${props.node.frontmatter.thumbnail.childImageSharp.fluid.src})`,
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }
        }
      />
      <div>
        {/* <Link to={props.node.fields.slug}> */}
        <h6 style={{ "font-weight": "normal", color: "black" }}>
          {props.node.frontmatter.title || props.node.fields.slug}
        </h6>
      </div>
    </Link>
  </article>
)
